import React, { useContext, useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { breakpoints, color, area, border } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';
// import PopupWindow, { invokePopup } from '../../shared/PopupWindow';
import { useModalOpen } from '../../shared/Modal';
import Popup from '../../shared/Popup';
import LoginForm from '../../Auth/Form/LoginForm';
import RegistrationForm from '../../Auth/Form/RegistrationForm';
import { StoreContext } from '../../../store';
import dictionary from '../../../utils/dictionary';
import { getLocalFile, isMobile } from '../../../utils/system';

// Images
import cherry from '../../../img/cherry.svg';
import sbobet from '../../../img/sbobet.svg';

const PlayForFreeWrap = styled('div')`
  border-radius: 8px;
  padding: ${area.blockPadding};
  position: relative;
  user-select: none;
  @media (min-height: ${breakpoints.md}) {
    padding: 0 20px;
  }
  .title-block {
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
    .sbobet {
      position: absolute;
      top: 25%;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 85vw;

      @media (max-width: ${breakpoints.sm}) {
        width: 400px;
        height: 75px;
        left: 0;
      }
    }
    img.iconImage {
      margin: 0;
      @media (max-width: ${breakpoints.md}) {
        padding: 0;
        //position: absolute;
        top: -155%;
        left: 0;
        right: 0;
        margin: auto;
        width: 70px;
      }
    }
  }
  .h3 {
    margin: 0 auto 40px;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.lightningYellow};
    text-align: center;

    @media (max-width: ${breakpoints.tl}) {
      padding: 0;
      text-align: center;
    }
  }
  .play-button {
    position: absolute;
    z-index: 1;
    width: 132px;
    height: 44px;
    line-height: 44px;
    background-color: ${color.lightningYellow};
    color: ${color.black};
    font-weight: 500;
    border-radius: 14px;
    border: none;
    outline: none;
    cursor: pointer;
    visibility: hidden;
    &:hover,
    &:active {
      background-color: ${color.lightningYellow};
    }

    @media (max-width: ${breakpoints.tl}) {
      background: rgb(254, 17, 96);
      background: linear-gradient(0deg, rgba(254, 17, 96, 1) 5%, rgba(255, 124, 100, 1) 85%);
      color: ${color.white};
      svg {
        path {
          fill: ${color.white} !important;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    padding-top: 19px;
    .play-button {
      left: 25px;

      @media (max-width: ${breakpoints.xxs}) {
        left: 8px;
      }
    }
    .h3 {
      margin-bottom: 0.7rem;
      font-size: 30.8px;
    }
  }
`;

const PlayForFreeAll = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  #play-for-free-video {
    width: 800px;
    height: 600px;
  }

  @media (max-width: ${breakpoints.md}) {
    #play-for-free-video {
      width: 100%;
      height: calc(50vh - 130px);
      min-height: 270px;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    #play-for-free-video {
      height: auto;
      min-height: 200px;
    }
  }
`;

const PlayForFreeVideo = styled('div')`
  width: 360px;
  background: ${color.darkRed};
  height: 400px;
  position: relative;

  .gatsby-image-wrapper {
    height: 100%;
    border-radius: ${border.borderRadius4};

    @media (max-width: ${breakpoints.md}) {
      margin-top: 0;
    }
  }

  .play-button {
    left: calc(50% - 67px);
    top: 40%;
    visibility: unset;
    transition: 0.3s;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;

    background-size: 200%;

    &:hover {
      background-position: right center;
    }
  }

  .terms-button {
    position: absolute;
    top: 50%;
    left: calc(50% - 67px);
    margin: 20px 0 0 0;
    width: 132px;
    height: 44px;
    line-height: 44px;
    padding: 0;
    background: none;
    border: 1px solid ${color.lightningYellow};
    color: ${color.lightningYellow};
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    z-index: 1;
    visibility: hidden;
    transition-duration: 0.2s;
    border-radius: ${border.borderRadius14};
    &:hover {
      background: rgb(255, 124, 100);
      background: linear-gradient(0deg, rgba(254, 17, 96, 1) 5%, rgba(255, 124, 100, 1) 85%);
      color: ${color.white};
      border: none;
    }

    @media (max-width: ${breakpoints.tl}) {
      border: 1px solid rgb(255, 124, 100);
      color: rgb(255, 124, 100);
    }
  }

  &:hover,
  &:active {
    .terms-button {
      visibility: visible;
    }
    img {
      filter: brightness(20%);
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    height: 100%;

    &:hover,
    &:active {
      .terms-button {
        visibility: visible;
      }
      img {
        filter: brightness(20%);
      }
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    height: 100%;
    flex-basis: 100%;
  }
`;

const GameAds = styled('div')`
  width: calc(100% - 380px);
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  grid-row-gap: 20px;

  @media (max-width: ${breakpoints.md}) {
    margin-top: 12px;
    width: 100%;
    margin-left: 0;

    grid-template-columns: repeat(2, calc(50% - 6px));
    grid-gap: 12px;
    grid-auto-rows: max-content;
    grid-row-gap: 12px;
  }
`;

const GameAd = styled('div')`
  height: auto;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    height: auto;
    .coming-soon {
      font-size: 0.8rem;
    }
  }

  .show-more,
  .coming-soon {
    width: 120px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -60px;
    display: none;
  }
  .coming-soon {
    background: transparent;
    color: ${color.white};
    padding: 12px;
    text-align: center;
    pointer-events: none;
    width: 150px;
    margin: -25px 0 0 -75px;
  }
  .play-button {
    top: 45%;
    background-size: 200%;
    transition: 0.3s;
    left: 0;
    right: 0;
    margin: auto;

    @media (max-width: ${breakpoints.tl}) and (orientation: portrait) {
      top: 42%;
      background: rgb(254, 17, 96);
      background: linear-gradient(0deg, rgba(254, 17, 96, 1) 5%, rgba(255, 124, 100, 1) 85%);
      color: ${color.white};
    }

    @media (max-width: ${breakpoints.sm}) and (min-width: ${breakpoints.xs}) and (orientation: landscape) {
      top: 42%;
    }

    @media (max-height: ${breakpoints.xxs}) and (orientation: landscape) {
      top: 42%;
    }

    &:hover {
      background-position: right center;
    }
  }
  .coming-soon {
    background: transparent;
    color: ${color.white};
    padding: 12px;
    text-align: center;
    pointer-events: none;
    width: 150px;
    margin: -25px 0 0 -75px;
  }
  .ribbon {
    position: absolute;
    top: 12%;
    left: -15px;
    z-index: 1;
    padding: 5px 10px;
    background: ${color.red};
    font-style: italic;
    font-weight: bold;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 28px solid ${color.red};
      border-right: 15px solid transparent;
      position: absolute;
      right: -15px;
      top: 0;
    }
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 28px solid ${color.red};
      border-left: 15px solid transparent;
      position: absolute;
      left: -15px;
      top: 0;
    }
  }
  &:hover,
  &:active {
    .play-button {
      visibility: visible;
    }
    .ribbon {
      opacity: 0.8;
    }
    .coming-soon,
    .show-more {
      display: inline-block;
    }
    & > a > span {
      visibility: unset;
    }
  }
  a,
  .a {
    display: block;
    height: 100%;
    &:hover,
    &:active {
      img {
        filter: brightness(20%);
      }
    }
  }
  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius4};
    height: 100%;
    position: relative;
  }
`;

const GameTitle = styled.span`
  position: absolute;
  top: 20px;
  color: white;
  left: 0;
  width: 100%;
  text-align: center;
  visibility: hidden;
`;

// REFACTOR.
// Need to refactor render method. That's monkey code now.
const PlayForFree = (exclCode) => {
  const [mobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(isMobile(960));
  });
  const [state] = useContext(StoreContext);
  const {
    user: { userName },
  } = state;
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const openLoginForm = useModalOpen('home-login-popup');

  const gameClick = (e, slide_title, game_title, slide_description) => {
    if (!userName && game_title !== 'Choy-Sun-Doa') {
      openLoginForm(e);
    }
    if (slide_description) {
      return false;
    }
    return false;
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPage(filter: { slug: { eq: "home" } }) {
            edges {
              node {
                acf {
                  play_for_free_title
                  play_for_free_video_file {
                    source_url
                  }
                  play_for_free_video_cover {
                    alt_text
                    title
                    path
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 620) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  gatsby_freespin_games_mobile {
                    alt_text
                    title
                    path
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 276) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  gatsby_freespin_games {
                    alt_text
                    title
                    path
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 400) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          allWordpressPage: { edges },
        } = data;
        const {
          node: {
            acf: {
              play_for_free_video_cover,
              play_for_free_video_file,
              gatsby_freespin_games,
              gatsby_freespin_games_mobile,
            },
          },
        } = edges[0];

        const freespin_games = mobile ? gatsby_freespin_games_mobile : gatsby_freespin_games;

        return (
          <PlayForFreeWrap id="home-play-for-free">
            <div className="title-block">
              <img className="iconImage" src={cherry} alt="cherry" />
              <h1 className="h3 gradient">
                <span>{data.allWordpressPage.edges[0].node.acf.play_for_free_title}</span>
              </h1>
              <img className="sbobet" src={sbobet} alt="sbobet" />
            </div>
            <PlayForFreeAll>
              <PlayForFreeVideo id="home-play-for-free-video">
                {play_for_free_video_file?.source_url && (
                  <button type="button" className="play-button" onClick={togglePopup}>
                    <IcomoonIcon className="" icon="play3" color={color.black} size={12} alt="video" />
                    <span>{dictionary.watchVideo}</span>
                  </button>
                )}
                <a href="/freespin-terms-and-conditions" className="button transparent-button terms-button">
                  กติกา
                </a>
                {play_for_free_video_cover &&
                  play_for_free_video_cover.alt_text &&
                  getLocalFile(play_for_free_video_cover.localFile) && (
                  <Img fluid={getLocalFile(play_for_free_video_cover.localFile)} alt="play for free" />
                )}
              </PlayForFreeVideo>

              <GameAds id="play-for-free-games">
                {freespin_games?.map((item) => {
                  const {
                    localFile = {},
                    acf: { slide_description, slide_url },
                    title,
                    alt_text,
                  } = item;

                  if (slide_description && slide_url && title !== exclCode) {
                    return (
                      <GameAd key={item.path}>
                        {slide_description && slide_url === '#' && (
                          <span className="coming-soon">{slide_description}</span>
                        )}
                        {slide_url ? (
                          <a
                            href={slide_url}
                            disabled={slide_url === '#'}
                            title={alt_text}
                            onClick={(e) => gameClick(e, title, alt_text, slide_description)}
                          >
                            {!item.acf.slide_description && <span className="button show-more">เล่นเลย</span>}
                            {localFile?.childImageSharp && item.alt_text && (
                              <Img fluid={localFile?.childImageSharp.fluid} alt={item.alt_text || 'text'} />
                            )}
                            {item.acf.slide_url !== '#' && (
                              <>
                                <GameTitle>{item.title}</GameTitle>
                                <button
                                  type="button"
                                  className="play-button"
                                  disabled={item.acf.slide_url === '#'}
                                  onClick={(e) => {
                                    gameClick(e, item.title, item.alt_text, item.acf.slide_description);
                                  }}
                                >
                                  <span>{dictionary.gameTitleBlueButton}</span>
                                </button>
                              </>
                            )}
                          </a>
                        ) : (
                          <div className="a">
                            {getLocalFile(localFile) && alt_text && (
                              <Img fluid={getLocalFile(localFile)} alt={alt_text || 'text'} />
                            )}
                          </div>
                        )}
                      </GameAd>
                    );
                  }
                  return null;
                })}
              </GameAds>

              {play_for_free_video_file?.source_url && isOpen && (
                <Popup onClose={togglePopup} isOpen={isOpen} title="Free Spin" headerFontSize="26px">
                  <iframe
                    id="play-for-free-video"
                    title="Free Spin"
                    src={play_for_free_video_file.source_url}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                    allowFullScreen="allowFullScreen"
                    frameBorder={0}
                  />
                </Popup>
              )}

              {!userName && (
                <div>
                  <LoginForm popupId="home-login-popup" />
                  <RegistrationForm popupId="home-registration-popup" />
                </div>
              )}
            </PlayForFreeAll>
          </PlayForFreeWrap>
        );
      }}
    />
  );
};

export default PlayForFree;
