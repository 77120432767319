/* eslint-disable max-len */
import React from 'react';

const StepsArrow = () => (
  <>
    <svg height="112" viewBox="0 0 200 112" width="200" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="awdskdlqwj" x1="97.773%" x2="61.487%" y1="59.43%" y2="34.659%">
          <stop offset="0" stopColor="#211e97" />
          <stop offset="1" stopColor="#18174f" stopOpacity="0" />
        </linearGradient>
      </defs>
      <path
        d="m343 0 40.284 51.044c2.293 2.906 2.293 7.006 0 9.912l-40.284 51.044h-158v-112z"
        fill="url(#awdskdlqwj)"
        fillRule="evenodd"
        opacity=".402"
        transform="translate(-185)"
      />
    </svg>
  </>
);

export default StepsArrow;
