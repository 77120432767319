import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonIcon from './IcomoonIcon';
import { breakpoints, color } from '../../utils/style';

const Popup = ({ children, text, title, onClose, headerFontSize, className }) => (
  <Wrapper className={className}>
    <Component>
      <Header fonstSize={headerFontSize}>
        {title}
        <CloseButton onClick={onClose}>
          <IcomoonIcon icon="close" color={color.white} size={30} />
        </CloseButton>
      </Header>
      <Body>
        {children || text}
      </Body>
    </Component>
  </Wrapper>
);

Popup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  text: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Popup.defaultProps = {
  text: '',
  title: '',
  children: [],
};

export default Popup;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Artem Gorovoi, mb you can write this with another implementation */
  &#withdraw-popup-id {
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  
    & > div {
      width: 80%;
      min-height: 93%;

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }
    }
    
    & > #withdraw-popup {
      overflow-y: unset;
    }
  }
`;

const Component = styled.div`
  padding: 20px;
  margin: 0 auto !important;
  position: absolute;
  z-index: 9999;
  background: #10192e;
  border-radius: 8px;
  overflow: hidden;
  background-size: 100% 100%;

  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: 80%;
    height: 50vh;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 90%;
    height: auto;
    left: auto;
  }
  
  @media (max-width: ${breakpoints.xs}) and (orientation: portrait) {
    padding: 8px;
    max-width: 100vw;
    margin-left: 0px;

    /* Artem Gorovoi, mb you can write this with another implementation */
    &#free-spin-home-popup {
      left: calc(50% - 150px);
      width: 300px;
      height: 281px;
    }
  }
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 50px 20px 0;
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: ${(props) => props.fontSize || '26px'};
  font-weight: bold;
  color: ${color.blue};
  text-align: center;
`;

const Body = styled.div`
  width: 100%;
  padding: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: ${color.white};
  position: absolute;
  top: 24px;
  right: 24px;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }
  @media (max-width: ${breakpoints.xs}) {
    top: 25px;
    right: 15px;
  }
`;
