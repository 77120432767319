import React, { useEffect, useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { color, breakpoints, border, area } from '../../../utils/style';
import { getFile, isMobile, validateLink } from '../../../utils/system';

// Images
import lottery from '../../../img/lottery.svg';
import liveCasino from '../../../img/live-casino.svg';
import virtualSports from '../../../img/virtual-sports.svg';
import sports from '../../../img/sports.svg';
import slots from '../../../img/slots.svg';
import racing from '../../../img/racing.svg';
import heart from '../../../img/heart.svg';

const Wrapper = styled('div')`
  border-radius: ${border.borderRadius4};
  padding: ${area.blockPadding};

  & * {
    user-select: none;
  }

  @media (max-width: ${breakpoints.md}) {
    // padding: 20px 15px;
  }
`;

const WrapperGame = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-gap: 40px;
  
  @media (max-width: ${breakpoints.tl}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${breakpoints.xs}) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
  }

  .game {
    margin-bottom: 2%;
    display: block;
    position: relative;
    overflow: hidden;
    &:hover {
      opacity: .8;
    }
    border-radius: ${border.borderRadius4};
    @media (max-width: ${breakpoints.md}) {
      width: 100%;
      &:last-child {
        margin-bottom: 10px;
      }
    }

    .arrowInCircle {
      display: none;
    }

    &:hover .readMore {
      opacity: 1;
      transform: translateY(0px);
    }
    &:hover .title .iconImage {
        box-shadow: 0 0 6px 17px #ff00006b inset, 0 0 22px 12px #ff000073;
        border-radius: 38%;
        opacity: 1;
      }
    &:hover .arrowInCircle {
        position: absolute;
        border-radius: 0;
        opacity: 1;
        display: inherit;

        @media (min-width: ${breakpoints.tl}) {
          left: 200%;
          top: 20%;
        }
      }
    }
  }

  .game:hover:after {
    width: 100%;
  }

  .text {
    position: absolute;
    bottom: 10px;
    opacity: 1;
    left: -16px;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 50px;
    justify-content: flex-end;
    &:hover {
      opacity: 1 !important;
    }
  }

  .title {
    font-size: 2rem;
    color: ${color.white};
    font-weight: 600;
    text-shadow: 0 0 11px #39c4fc, -1px -1px rgba(0, 0, 0, 0.5);
    position: relative;
    opacity: 1;

    @media (max-width: ${breakpoints.md}) {
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 26.4px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        color: ${color.catskillWhite};
        width: 100%;
      }
    .iconImage {
      opacity: 1 !important;
      position: absolute;
      left: 0;
      top: -120%;
      transition-duration: 0.4s;
      &:hover {
        box-shadow: 0 0 6px 17px #ff00006b inset, 0 0 22px 12px #ff000073;
        border-radius: 38%;
        opacity: 1 !important;
        // box-shadow: 0 0 30px 5px red inset, 0 0 30px 10px red;
        // border-radius: 20%;
      }
      @media (max-width: ${breakpoints.md}) {
        width: 30px;
        height: 30px;
        top: -35px;
      }
    }
  }

  .readMore {
    text-transform: uppercase;
    color: ${color.white};
    opacity: 0;
    transform: translateY(-35px);
    transition-duration: 0.4s;
  }
`;

const ImgWrapper = styled('div')`
  border-radius: ${border.borderRadius4} ${border.borderRadius4} 4px 4px;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: inset 0px -4px 0px 0px #ef472b;
  }

  & .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(0, 0, 0, 0.7) 100%);
  }
`;

const HeaderText = styled('span')`
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 26px;
  font-weight: bold;
  color: ${color.lightningYellow};
  margin-left: 50px;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 60px;
  }
`;

const HeaderWrapper = styled('div')`
  position: relative;
  margin-bottom: 30px;

  img {
    width: 40px;
    position: absolute;
    top: 5px;

    @media (max-width: ${breakpoints.tl}) {
      width: 50px;
      top: 2px;
    }
  }
`;

const SubText = styled.span`
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 13px;
  line-height: 1.62;
  color: ${color.alabaster};
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  @media (min-width: ${breakpoints.tl}) {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 68px;
  }
`;

const BlockWrapper = styled.div`
  width: 100%;

  & .game {
    position: relative;
    margin-bottom: 20px;

    &:hover {
      & .link-button {
        opacity: 1;
      }
    }

    & .link-button {
      position: absolute;
      top: 0;
      right: 0;

      height: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: 1s;

      & > svg {
        margin-right: 50px;
      }
    }
  }

  @media (min-width: ${breakpoints.tl}) {
    //margin: 0 7px;
    //margin-bottom: 40px;
    //width: calc((100% / 3) - 14px);
    :nth-of-type(3n-2) {
      //padding-left: 0;
    }

    :nth-of-type(3n) {
      //padding-right: 0;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    a {
      .link-button {
        display: none;
      }
    }

    & .game {
      margin-bottom: 10px;
    }

    :nth-of-type(n) {
      .text {
        bottom: 10px;

        .arrowInCircle {
          top: -100%;
          right: -120%;
        }
      }
    }
    :nth-of-type(2) {
      .text {
        bottom: -8px;

        .arrowInCircle {
          top: 0;
        }
      }
    }

    :nth-of-type(3) {
      .text {
        .arrowInCircle {
          top: -30%;
        }
      }
    }

    :nth-of-type(6),
    :nth-of-type(5) {
      .text {
        .arrowInCircle {
          top: -20%;
        }
      }
    }
    :nth-of-type(1),
    :nth-of-type(4) {
      .text {
        bottom: 20px;
      }
    }
  }
`;

const Games = () => {
  const [resolution, setResolution] = useState(false);

  const onResize = () => {
    setResolution(isMobile(400));
  };
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const icons = [sports, virtualSports, liveCasino, lottery, racing, slots];
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressPage(filter: { slug: { eq: "home" } }) {
            edges {
              node {
                acf {
                  gallery_games {
                    alt_text
                    title
                    id
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1200) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  gallery_games_mobile {
                    alt_text
                    title
                    id
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 288, maxHeight: 140) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { allWordpressPage: { edges = [{}] } = {} } = data;
        const { node: { acf: { gallery_games = {}, gallery_games_mobile = {} } = {} } = {} } = edges[0];
        const images = resolution ? gallery_games_mobile : gallery_games;
        return (
          <Wrapper id="games">
            <HeaderWrapper>
              <img className="iconImage" src={heart} alt="heart" />
              <HeaderText>เกมเดิมพัน</HeaderText>
            </HeaderWrapper>
            <WrapperGame>
              {images?.map((item) => {
                const {
                  acf: { slide_url, slide_description },
                  altText,
                } = item;
                return (
                  <BlockWrapper key={slide_url}>
                    <Link to={validateLink(slide_url)} className="game">
                      <ImgWrapper>
                        <Img className="gameImg" fluid={getFile(item)} alt={altText || 'Gclub'} />
                      </ImgWrapper>
                    </Link>
                    <SubText>{slide_description}</SubText>
                  </BlockWrapper>
                );
              })}
            </WrapperGame>
          </Wrapper>
        );
      }}
    />
  );
};

export default Games;
