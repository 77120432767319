import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { breakpoints, color } from '../../utils/style';
import { getLocalFile } from '../../utils/system';

const SlideWrap = styled('div')`
  position: relative;
  text-align: left;
  img {
    display: none;
  }
  .button {
    position: absolute;
    bottom: 130px;
    left: 70px;
  }
  & .gatsby-image-wrapper {
  }
  @media (max-width: ${breakpoints.lg}) {
    .button {
      bottom: 106px;
      left: 62px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .button {
      padding: 7px 18px;
      bottom: 30px;
      left: 18px;
    }
  }
  @media (max-width: ${breakpoints.xxs}) {
    .button {
      width: 100%;
      height: 100%;
    }
  }
`;

const TextBlock = styled('div')`
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  padding: 40px 70px 120px 70px;
  .h1 {
    margin: 0;
    font-size: 3.4rem;
  }
  .h2 {
    margin: 0;
    font-weight: 400;
    font-size: 50px;
  }
  p {
    color: ${color.blue};
    font-weight: 600;
    font-size: 5.2rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 17px 15px;
    .h2 {
      font-size: 15px;
      margin-top: 0;
      text-transform: uppercase;
    }
    p {
      margin-top: 12px;
      font-size: 35px;
      text-transform: uppercase;
      line-height: 1;
    }
  }
`;

const InvisibleA = styled.a`
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  visibility: none;
`;

const SlickSlide = ({ img, altText, line1, line2, link }) => (
  <SlideWrap>
    {
      getLocalFile(img) &&
        <Img fluid={getLocalFile(img)} alt={altText || 'slide'} />
    }
    {(line1 || line2) && (
      <TextBlock>
        {line1 && <h2 className="h2">{line1}</h2>}
        {line2 && <p>{line2}</p>}
      </TextBlock>
    )}

    {link && <InvisibleA href={link} />}
  </SlideWrap>
);
SlickSlide.propTypes = {
  img: PropTypes.shape.isRequired,
  altText: PropTypes.string.isRequired,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default SlickSlide;
