import React from 'react';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { color, formStyles, breakpoints, formsAutoFillingStyles } from '../../../utils/style';
import PopupWindow, { invokePopup, closePopup } from '../../shared/PopupWindow';
import { freespinRegiter } from '../../../utils/api/freespin';

const Wrapper = styled('div')`
  ${formStyles};
  ${formsAutoFillingStyles};
  position: relative;
  padding: 0 0 20px;
  margin-top: -10px;
  text-align: left;
  .loading {
    position: relative;
  }
  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
  .two-fields {
    .fieldWrap {
      width: 48%;
      display: inline-block;
      vertical-align: top;
      &:first-of-type {
        margin-right: 4%;
      }
    }
  }
  .red-button {
    float: right;
  }
  .button {
    svg {
      margin-left: 5px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .two-fields {
      .fieldWrap {
        width: 100%;
        padding-bottom: 10px;
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
  }
`;

const MsgWrap = styled('div')`
  margin: 30px;
  .msg-holder {
    display: none;
    padding: 5px 10px;
    text-align: center;
    &.visible {
      display: block;
    }
    &.errorMsg {
      background-color: ${color.yellow};
      color: ${color.black};
    }
  }
`;
const hideAllErrors = () => {
  const errorBoxes = document.getElementsByClassName('errorMsg');
  if (errorBoxes.length) {
    Object.values(errorBoxes).forEach((element) => {
      element.classList.remove('visible');
    });
  }
};
// REFACTOR
// I started checking this file by bottom. Just rewrite it full. Here about 70% useless code.
const RegistrationFormOpen = (event, popupId) => {
  hideAllErrors();
  invokePopup(event, popupId);
};

const showErrorMsg = (msg) => {
  const errorMsg = document.getElementById('registrationErrorMsg');
  errorMsg.innerText = msg;
  errorMsg.classList.add('visible');
};

const showSuccessMsg = () => {
  const registrationForm = document.getElementById('registrationForm');
  registrationForm.style.display = 'none';
  const successMsg = document.getElementById('registrationSuccessMsg');
  successMsg.classList.add('visible');
  const regPopupTitle = document.getElementById('register-popup-window-title');
  regPopupTitle.innerHTML = 'สมัครสล็อตฟรี 15 ครั้ง';
};

const hideMsg = () => {
  const successMsg = document.getElementById('registrationSuccessMsg');
  successMsg.classList.remove('visible');
  const errorMsg = document.getElementById('registrationErrorMsg');
  errorMsg.classList.remove('visible');
  const registrationForm = document.getElementById('registrationForm');
  registrationForm.style.display = 'block';
};

const onSubmit = (values, { resetForm, setSubmitting }) => {
  freespinRegiter(values)
    .then((json) => {
      if (json.result) {
        resetForm({});
        showSuccessMsg();
      } else {
        showErrorMsg(json.message);
        setSubmitting(false);
      }
    })
    .catch((err) => {
      // console.log('Registration API error: ', err);
    });
};

const initialValues = {
  username: '',
  email: '',
  phone: '',
  line: '',
  password: '',
  register_type: { real_money: false, free_spin: false },
};

const Checkbox = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
}) => (
  <div className="minimal-custom-checkbox">
    <input name={name} id={id} type="checkbox" value={value} checked={value} onChange={onChange} onBlur={onBlur} />
    <label htmlFor={id}>{label}</label>
  </div>
);

const FormBody = () => (
  <Wrapper>
    <MsgWrap id="registrationMsg">
      <div className="errorMsg msg-holder" id="registrationErrorMsg">
        Failed sending form. Please try again
      </div>
      <div className="successMsg msg-holder" id="registrationSuccessMsg">
        <p>กรุณายืนยันสิทธิ์ ผ่านทาง Email</p>
        <p>ที่คุณได้ทำการสมัครไว้</p>
      </div>
    </MsgWrap>

    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (!(values.register_type.real_money || values.register_type.free_spin)) {
          errors.register_type = 'ต้องการอย่างน้อยหนึ่งรายการ';
        }
        return errors;
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('จำเป็นต้องใช้'),
        email: Yup.string().email().required('จำเป็นต้องใช้'),
        password: Yup.string().required('จำเป็นต้องใช้'),
        phone: Yup.string().required('จำเป็นต้องใช้'),
        line: Yup.string().required('จำเป็นต้องใช้'),
      })}
      render={({ errors, status, touched, isSubmitting, isValid, values }) => (
        <Form id="registrationForm">
          <div className="fieldWrap with-label">
            <label htmlFor="register-username-popup">Username</label>
            <Field
              type="text"
              name="username"
              placeholder="ยูสเซอร์เนม"
              id="register-username-popup"
              value={values.username || ''}
            />
            {errors.username && touched.username && <div className="error">! {errors.username}</div>}
          </div>
          <div className="fieldWrap with-label">
            <label htmlFor="register-password-popup">Password</label>
            <Field
              type="password"
              name="password"
              placeholder="รหัสผ่าน"
              id="register-password-popup"
              value={values.password || ''}
            />
            {errors.password && touched.password && <div className="error">! {errors.password}</div>}
          </div>
          <div className="fieldWrap with-label">
            <label htmlFor="register-popup-email">Email</label>
            <Field type="email" name="email" id="register-popup-email" placeholder="อีเมล" value={values.email || ''} />
            {errors.email && touched.email && <div className="error">! {errors.email}</div>}
          </div>
          <div className="two-fields">
            <div className="fieldWrap with-label">
              <label htmlFor="register-popup-phone">Phone</label>
              <Field
                type="text"
                name="phone"
                id="register-popup-phone"
                placeholder="Phone"
                value={values.phone || ''}
              />
              {errors.phone && errors.phone && touched.phone && <div className="error">! {errors.phone}</div>}
            </div>
            <div className="fieldWrap with-label">
              <label htmlFor="register-popup-line">Line ID</label>
              <Field type="text" name="line" id="register-popup-line" placeholder="LINE ID" value={values.line || ''} />
              {errors.line && touched.line && <div className="error">! {errors.line}</div>}
            </div>
          </div>
          <div className="checkboxGroupWrap">
            <p>เกมที่คุณสนใจ</p>
            <div className="fieldWrap">
              <Field
                component={Checkbox}
                name="register_type[real_money]"
                id="register-type-real-money-popup"
                label="สมัครเล่น สล็อตฟรีสปิน"
                value={
                  values.register_type && values.register_type.real_money ? values.register_type.real_money : false
                }
              />
            </div>

            <div className="fieldWrap">
              <Field
                component={Checkbox}
                name="register_type[free_spin]"
                id="register-type-free-spin-popup"
                label="สมัครเล่น คาสิโนด้วยเงินจริง"
                value={values.register_type && values.register_type.free_spin ? values.register_type.free_spin : false}
              />
            </div>

            {Object.keys(errors).length === 1 && errors.register_type && (
              <div className="error">! {errors.register_type}</div>
            )}
          </div>
          {status && status.msg && <div>{status.msg}</div>}
          <div className="a-center">
            <button
              aria-label="เข้าสู่ระบบ"
              className="button orange-button"
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              <span>ยืนยันการสมัคร</span>
            </button>
          </div>
        </Form>
      )}
    />
  </Wrapper>
);

const RegistrationForm = ({ popupId }) => (
  <PopupWindow title="สมัครสมาชิกฟรี" popupId="register-popup-window" extraClass="title-orange">
    <FormBody popupId={popupId} />
  </PopupWindow>
);

export { RegistrationFormOpen };
export default RegistrationForm;
